import React from 'react';
import CV from '../../assets/CV.pdf';
import { useTranslation } from 'react-i18next';

const CTA = () => {
  const { t } = useTranslation();

  return (
    <div className='cta'>
      <a href={CV} download className='btn'>{t('DownloadCV')}</a>
      <a href='#contact' className='btn btn-primary'>{t('LetsTalk')}</a>
    </div>
  )
}

export default CTA;
