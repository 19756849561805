import React from 'react';
import './about.css';
import ME from '../../assets/me-about.png';
import { FaAward } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { VscFolderLibrary } from 'react-icons/vsc';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <section id='about'>
      <h5>{t('GetToKnow')}</h5>
      <h2>{t('AboutMe')}</h2>

      <div className="container about_container">
        <div className='about_me'>
          <div className='about_me-image'>
            <img src={ME} alt='About Image' />
          </div>
        </div>

        <div className='about_content'>
          <div className='about_cards'>
            <article className='about_card'>
              <FaAward className='about_icon' />
              <h5>{t('Experience')}</h5>
              <small>{t('YearsWorking')}</small>
            </article>

            <article className='about_card'>
              <FiUsers className='about_icon' />
              <h5>{t('Clients')}</h5>
              <small>{t('Worldwide')}</small>
            </article>

            <article className='about_card'>
              <VscFolderLibrary className='about_icon' />
              <h5>{t('Projects')}</h5>
              <small>{t('Completed')}</small>
            </article>
          </div>

          <p>
            {t('Description')}
          </p>

          <a href='#contact' className='btn btn-primary'>{t('LetsTalk')}</a>
        </div>
      </div>
    </section>
  )
}

export default About;
