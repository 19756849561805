import React from 'react';
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const Experience = () => {
  const { t } = useTranslation();

  return (
    <section id='experience'>
      <h5>{t('WhatSkillsIHave')}</h5>
      <h2>{t('MyExperience')}</h2>

      <div className='container experience_container'>
        <div className='experience_frontend'>
          <h3>{t('FrontendDevelopment')}</h3>
          <div className='experience_content'>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('HTML')}</h4>
                <small className='text-light'>{t('Experienced')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('CSS')}</h4>
                <small className='text-light'>{t('Intermediate')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('Javascript')}</h4>
                <small className='text-light'>{t('Experienced')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('Bootstrap')}</h4>
                <small className='text-light'>{t('Experienced')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('Tailwind')}</h4>
                <small className='text-light'>{t('Experienced')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('React')}</h4>
                <small className='text-light'>{t('Experienced')}</small>
              </div>
            </article>
          </div>
        </div>
        {/*END OF FRONTEND*/}

        <div className='experience_backend'>
          <h3>{t('BackendDevelopment')}</h3>
          <div className='experience_content'>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('NodeJs')}</h4>
                <small className='text-light'>{t('Experienced')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('MongoDB')}</h4>
                <small className='text-light'>{t('Intermediate')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('PHP')}</h4>
                <small className='text-light'>{t('Intermediate')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('MySQL')}</h4>
                <small className='text-light'>{t('Basic')}</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>{t('Python')}</h4>
                <small className='text-light'>{t('Experienced')}</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;
