import React from 'react';
import './services.css';
import { BiCheck } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();

  return (
    <section id='services'>
      <h5>{t('WhatIOffer')}</h5>
      <h2>{t('Services')}</h2>

      <div className='container services_container'>
        <article className='service'>
          <div className='service'>
            <div className='service_head'>
              <h3>{t('UIUXDesign')}</h3>
            </div>
          </div>
          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('DesignReferences')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('GraphicInterface')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('AnimationPrototype')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('UIGuidelines')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('DesignReview')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('StrategicallyEngineered')}</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className='service'>
          <div className='service'>
            <div className='service_head'>
              <h3>{t('WebDevelopment')}</h3>
            </div>
          </div>
          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('AnalyticsInterpretation')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('CompetitionMonitoring')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('HTMLEmailTemplate')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('BlogCustomization')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('PayPerClick')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('SalesPresentation')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('UsabilityTesting')}</p>
            </li>
          </ul>
        </article>
        {/* WEB DEVELOPMENT */}
        <article className='service'>
          <div className='service'>
            <div className='service_head'>
              <h3>{t('ContentCreation')}</h3>
            </div>
          </div>
          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('BlogPosts')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('LongFormContent')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('OnlineGuides')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('Infographics')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('Graphics')}</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>{t('Videos')}</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services;
