import React from 'react';
import './footer.css';
import { FaFacebookF } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import { IoLogoTwitter } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <a href='#' className='footer_logo'>Zalán</a>
      <ul className='permalinks'>
        <li><a href='#'>{t('Home')}</a></li>
        <li><a href='#about'>{t('About')}</a></li>
        <li><a href='#experience'>{t('Experience')}</a></li>
        <li><a href='#services'>{t('Services')}</a></li>
        <li><a href='#portfolio'>{t('Portfolio')}</a></li>
        <li><a href='#testimonials'>{t('Testimonials')}</a></li>
        <li><a href='#contact'>{t('Contact')}</a></li>
      </ul>

      <div className='footer_socials'>
        <a href='https://facebook.com'><FaFacebookF/></a>
        <a href='https://instagram.com'><FiInstagram/></a>
        <a href='https://twitter.com'><IoLogoTwitter/></a>
      </div>

      <div className='footer_copyright'>
        <small>&copy; Zalán Portfolio. {t('AllRightsReserved')}</small>
      </div>
    </footer>
  )
}

export default Footer;
