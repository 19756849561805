import React from 'react';
import './testimonials.css';
import AVTR1 from '../../assets/avatar1.jpg';
import AVTR2 from '../../assets/avatar2.jpg';
import AVTR3 from '../../assets/avatar3.jpg';
import AVTR4 from '../../assets/avatar4.jpg';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

const data = [
  {
    avatar: AVTR1,
    name: 'Tina Snow',
    review: 'Review1'
  },
  {
    avatar: AVTR2,
    name: 'Shatta Wale',
    review: 'Review2'
  },
  {
    avatar: AVTR3,
    name: 'Tina Snow',
    review: 'Review3'
  },
  {
    avatar: AVTR4,
    name: 'Tina Snow',
    review: 'Review4'
  },
];

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <section id='testimonials'>
      <h5>{t('ReviewFromClients')}</h5>
      <h2>{t('TestimonialsTitle')}</h2>

      <Swiper
        className='container testimonials_container'
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {
          data.map(({ avatar, name, review }, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className='client_avatar'>
                  <img src={avatar} alt={name} />
                </div>
                <h5 className='client_name'>{name}</h5>
                <small className='client_review'>{t(review)}</small>
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </section>
  );
};

export default Testimonials;
