import React from 'react';
import './portfolio.css';
import IMG1 from '../../assets/portfolio1.png';
import IMG2 from '../../assets/portfolio2.png';
import IMG3 from '../../assets/portfolio3.png';
import IMG4 from '../../assets/portfolio4.png';
import IMG5 from '../../assets/portfolio5.png';
import IMG6 from '../../assets/portfolio6.png';
import { useTranslation } from 'react-i18next';

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'title1',
    github: './out/index.html',
    demo: 'https://www.figma.com/file/whraClyOnTT99oG7uNlpge/Untitled?type=design&t=Eo76dCzOmbsCkvIn-6'
  },
  {
    id: 2,
    image: IMG2,
    title: 'title2',
    github: 'https://github.com',
    demo: 'https://www.figma.com/file/rO3phKDbMpzd3ANbXWnLPh/Untitled?type=design&t=Eo76dCzOmbsCkvIn-6'
  },
  {
    id: 3,
    image: IMG3,
    title: 'title3',
    github: 'https://github.com',
    demo: 'https://www.figma.com/file/oLaMbQnU4HVBS2Ueho2T9W/Untitled?type=design&t=Eo76dCzOmbsCkvIn-6'
  },
  {
    id: 4,
    image: IMG4,
    title: 'title4',
    github: 'https://github.com',
    demo: 'https://www.figma.com/file/h7rRgHGu1emOXJB4mRQwjg/Untitled?type=design&t=Eo76dCzOmbsCkvIn-6'
  },
  {
    id: 5,
    image: IMG5,
    title: 'title5',
    github: 'https://github.com',
    demo: 'https://www.figma.com/file/m2IahdMBEbZFeturGwzAcB/Untitled?type=design&t=Eo76dCzOmbsCkvIn-6'
  },
  {
    id: 6,
    image: IMG6,
    title: 'title6',
    github: 'https://github.com',
    demo: 'https://www.figma.com/file/FVpBoUHNE79Bs52m2ub6u5/Untitled?type=design&t=Eo76dCzOmbsCkvIn-6'
  },
];

const Portfolio = () => {

  const { t } = useTranslation();
  return (
    <section id='portfolio'>
      <h5>{t('MyRecentWork')}</h5>
      <h2>{t('Portfolio')}</h2>
      <div className='container portfolio_container'>
        {
          data.map(({id, image, title, github, demo}) => {
            return(
            <article key={id} className='portfolio_item'>
                <div className='portfolio_item-image'>
                  <img src={image} alt={t(title)}/>
                </div>
                <h3>{t(title)}</h3>
                <div className='portfolio_item-cta'>
                  <a href={github} className='btn' target='_blank' rel="noreferrer">{t('Github')}</a>
                  <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">{t('LiveDemo')}</a>
                </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio;