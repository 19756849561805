import React from 'react';
import './header.css';
import CTA from './CTA';
import ME from '../../assets/me2.png';
import HeaderSocials from './HeaderSocials';
import 'animate.css';
import LanguageSwitcher from '../languageswitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Header = () => {
  var ReactRotatingText = require('react-rotating-text');
  const { t } = useTranslation();

  return (
    <header>
      <div className='container header_container'>
        <h5>{t('Hello')}</h5>
        <h1>Zalán Dominik</h1>
        <LanguageSwitcher/>
        <h2>
          <ReactRotatingText emptyPause={2000} typingInterval={200} deletingInterval={200} items={[t('WebDeveloper'), t('WebDesigner'), t('UIUXDesigner')]} />
        </h2>
        <h5 className='text-light'>{t('FullstackDeveloper')}</h5>
        <CTA />
        <HeaderSocials/>
        <div className='me'>
          <img src={ME} alt='me'/>
        </div>
        <a href='#contact' className='scroll_down'>{t('ScrollDown')}</a>
      </div>
    </header>
  )
}

export default Header;
