import React, { useRef, useState } from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { RiMessengerLine } from 'react-icons/ri';
import { BsWhatsapp } from 'react-icons/bs';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);

    emailjs.sendForm('service_sk46ekx', 'template_ykuybam', form.current, 'Sh_3mOH_tsCvBemlE').then(
      (res) => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000);
      },
      (err) => {
        setLoading(false);
        alert(t('Failed to send the message, please try again.'));
      }
    );
    e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>{t('GetInTouch')}</h5>
      <h2>{t('ContactMe')}</h2>

      <div className='container contact_container'>
        <div className='contact_options'>
          <article className='contact_option'>
            <MdOutlineEmail className='contact_option-icon'/>
            <h4>{t('Email')}</h4>
            <h5>digitalactivision@gmail.com</h5>
            <a href='mailto:digitalactivision@gmail.com' target="_blank" rel="noreferrer">{t('SendAMessage')}</a>
          </article>
          <article className='contact_option'>
            <RiMessengerLine className='contact_option-icon'/>
            <h4>{t('Messenger')}</h4>
            <h5>zalandominik</h5>
            <a href='https://m.me/zalandominik' target="_blank" rel="noreferrer">{t('SendAMessage')}</a>
          </article>
          <article className='contact_option'>
            <BsWhatsapp className='contact_option-icon'/>
            <h4>{t('WhatsApp')}</h4>
            <h5>+36204881977</h5>
            <a href='https://api.whatsapp.com/send?phone=+36204881977' target="_blank" rel="noreferrer">{t('SendAMessage')}</a>
          </article>
        </div>
        {/*END OF CONTACT OPTIONS*/}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder={t('YourFullName')} required/>
          <input type="email" name='email' placeholder={t('YourEmail')} required/>
          <textarea name='message' rows="7" placeholder={t('YourMessage')} required></textarea>
          <button type='submit' className='btn btn-primary'>{t('SendMessage')}</button>
        </form>
        {loading && (
          <div className='loading-overlay'>
            <Oval
              height={100}  // Increased size
              width={100}   // Increased size
              color="#00BFFF"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#00BFFF"
              strokeWidth={4} // Increased stroke width
              strokeWidthSecondary={4} // Increased stroke width
            />
          </div>
        )}
        {success && (
          <div className='success-message'>
            <p>{t('Message sent successfully!')}</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default Contact;
